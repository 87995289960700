import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import deTranslations from './intl/de.json';
import enTranslations from './intl/en.json';

i18n.use(initReactI18next).init({
  resources: {
    de: { translation: deTranslations },
    en: { translation: enTranslations },
  },
  lng: 'de', // Set the initial language
  fallbackLng: 'de', // Fallback language if translation for the current language is not available
  interpolation: {
    escapeValue: false, // React components within translations will not be escaped
  },
});

export default i18n;
